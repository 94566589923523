.resumeContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  h2 {
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.2px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: var(--text-color);
    margin: 1rem 0;
  }

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.textContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: var(--text-color);
  font-family: Roboto, sans-serif;
  font-weight: normal;
  span {
    font-weight: 500;
  }
}

.propertyDetailsButton {
  background-color: var(--primary-blue);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--primary-blue-dark);
  }
  padding: 0.5rem;
}
