@import '~bootstrap/scss/bootstrap-grid';
@import './fonts.scss';
@import '~antd/dist/antd.css';

:root {
  --app-red: rgba(255, 77, 79, 0.8);
  --app-success-color: rgb(51, 255, 0);
  --app-primary-color: #f0f0f0;
  --app-secondary-color: #ffffff;
  --app-terciary-color: #333333;
  --app-quarternary-color: rgb(68 65 183);
  --app-quinary-color: #47973c;

  // NEW COLOR SCHEME PALETTES:

  --primary-blue: #3b38c7;
  --primary-blue-light: #635fe9;
  --primary-blue-dark: #282685;

  --teal-normal: #50b5ed;
  --teal-light: #8dcff3;
  --teal-dark: #4087af;

  --purple-normal: #6864f0;
  --purple-light: #8683fc;
  --purple-dark: #3c3a8b;

  --violet-normal: #61289a;
  --violet-light: #8839d7;
  --violet-dark: #391260;

  --text-color: #4d4d4d;
  --white: #ffff;
  --background-gray: #f0f0f0;
}

body {
  color: rgba(0, 0, 0, 1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  background: var(--app-primary-color);
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background: #c0c0c0;
  border-radius: 20px;
  height: 40px;
}

.ant-layout-content {
  margin-left: 255px;
}

.ant-layout {
  background: var(--white);
  [data-is-logged='false'] {
    margin-left: 0;
  }
}

.ant-btn {
  display: inline-block;
  padding: 0 2em;
  background: var(--app-terciary-color);
  color: var(--app-secondary-color);
  text-decoration: none;
  font-weight: 400;
  transition: 0.5s all;
  border-color: var(--app-terciary-color);
  &:focus,
  &:hover,
  &:active {
    color: var(--app-secondary-color);
    background: var(--app-terciary-color);
    border-color: var(--app-terciary-color);
    opacity: 0.65;
  }
  svg {
    float: left;
    height: 16px;
    margin-left: -1px;
    margin-right: 5px;
    margin-top: 3px;
    width: 16px;
  }
}

.ant-btn-primary {
  border-color: var(--app-quinary-color);
  background-color: var(--app-quinary-color);
  &:focus,
  &:hover,
  &:active {
    background: var(--app-quinary-color);
    border-color: var(--app-quinary-color);
    opacity: 0.7;
  }
}

.btn-fab {
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 20px;
  height: 64px;
  padding: 0 32px;
  background-color: var(--app-quarternary-color);
  border-color: var(--app-quarternary-color);
  border-radius: 40px;
  &:focus,
  &:hover,
  &:active {
    background: #0065d4;
    border-color: #0065d4;
    opacity: 1;
  }
  svg {
    height: 32px;
    margin-top: 0px;
    margin-left: -15px;
    width: 32px;
  }
}

.btn-filter {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  border-radius: 10px;
  font-weight: 500;
  color: var(--app-quarternary-color);
  &:focus,
  &:hover,
  &:active {
    background: var(--app-quarternary-color);
    color: var(--app-secondary-color);
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0);
  }
}

.btn-search-filter {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  border-radius: 10px;
  font-weight: 500;
  color: var(--app-quarternary-color);
  &:focus,
  &:hover,
  &:active {
    background: var(--app-quarternary-color);
    color: var(--app-secondary-color);
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0);
  }
}

.ant-checkbox {
  margin: 15px 0;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  background: none;
  border: none;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  background: none;
  border: none;
  &:focus,
  &:hover,
  &:active {
    border: none;
  }
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  border: none;
  svg {
    color: #ef5350;
  }
  &:hover {
    border: none;
    color: #f44336;

    box-shadow: none;
  }
}

.btn-extra {
  display: flex;
  color: #fff;
  background: var(--app-red);
  align-items: center;
  justify-content: center;
  padding: 0 1.5em;
  border: none;
  justify-content: center;
  bottom: 10px;
  left: 10px;

  &:focus,
  &:hover,
  &:active {
    background: #d32f2f;
    color: #fff;
    border-color: none;
    border: none;
    opacity: 1;
  }
  svg {
    float: none;
    height: 16px;
    margin: 0;
    margin-right: 5px;
    width: 16px;
  }
}

.btn-add {
  background-color: var(--app-quinary-color);
  border-color: var(--app-quinary-color);
  color: #333;
  margin-top: 3em;

  padding: 0 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--app-quinary-color);
    border-color: var(--app-quinary-color);
    filter: brightness(85%);
    color: #333;
    opacity: 1;
  }
}

.btn-success {
  background-color: var(--app-quarternary-color);
  border-color: var(--app-quarternary-color);
  color: #fff;

  padding: 0px 30px;

  &:hover,
  &:focus {
    background-color: var(--app-quarternary-color);
    border-color: var(--app-quarternary-color);
    opacity: 0.85;
  }
}

.ant-drawer-body {
  padding: 0;
}

.ant-layout-footer {
  background: none;
  padding: 0;
}

.ant-layout-header {
  background: var(--app-secondary-color);
  color: var(--app-terciary-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 9;
  padding: 0;
  height: 90px;
  display: flex;
}

.ant-layout-sider.ant-layout-sider-dark {
  @media (max-width: 540px) {
    display: none;
  }
  background: var(--purple-normal);
  ul {
    background: var(--purple-normal);
  }
}

.ant-divider {
  border-top: 1px solid var(--app-terciary-color);
  opacity: 0.4;
}

.ant-input {
  color: #212121;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  &:hover {
    border-color: #413f3f;
  }
  &:focus {
    border-color: #413f3f;
    box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number {
  width: 100%;
  color: #212121;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  &:hover {
    border-color: #413f3f;
  }
  &:focus {
    border-color: #413f3f;
    box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  }
}

.ant-input-number-focused {
  box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
}

.ant-input-affix-wrapper {
  color: #212121;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  min-width: 0;
  background-image: none;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  &:hover {
    border-color: #413f3f;
  }
  &:focus {
    border-color: #413f3f;
    box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  }
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 0;
  padding-right: 0;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  border-color: #c0c0c0;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  background-color: var(--app-quarternary-color);
  color: var(--app-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  span {
    svg {
      margin: 0;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  &:hover {
    border-color: #413f3f;
    -webkit-box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
    box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #413f3f;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
  box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  border-radius: 5px;

  border: 1px solid #c0c0c0;
  &:hover {
    border-color: #413f3f;
  }
}

.ant-select-focused .ant-select-multiple .ant-select-selector {
  border-color: #413f3f;

  box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
}

.ant-select-selector {
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  &:hover {
    border-color: #413f3f;
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #413f3f;
  box-shadow: 0 0 0 1px rgba(30, 29, 31, 0.2);
}

.textLightColor {
  margin-top: -20px;
  color: #838383;
  text-align: center;
}

/**
 * UTILITIES
 */
.ml-1 {
  margin-left: 0.5rem;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.align-center {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  align-items: center;
}

/**
 * Buttons
 **/

/**
 * FORMS
 */
.ant-form-item {
  .ant-form-item-label {
    label {
      color: #333;
      font-size: 12px;
      padding-right: 4px;
      &:after {
        display: none;
      }
    }
  }

  .ant-form-item-control {
    .ant-form-explain {
      color: #f44336;
      font-size: 12px;
      font-style: italic;
      margin-top: 0px;
      min-height: auto;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 14px;
  }

  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 32px !important;
    line-height: 32px !important;
  }
}

/**
 * TABLES
 */
.ant-table-body {
  margin: 0;
}

.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.ant-table-expanded-row {
  // background-color: #f4f4f4;

  td {
    padding: 0;
  }
}

/** 
 * TOOLTIP
 */
.ant-tooltip-inner {
  font-size: 12px;
  min-height: 18px;
  padding: 5px 7px;
  a {
    color: var(--white);
  }
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: rgba(255, 77, 79, 0.8);
  font-style: italic;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  transition: none;
}

.ant-modal-footer {
  text-align: center;
}

.carousel.carousel-slider {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.center-col {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  @media (max-width: 784px) {
    margin: 5px;
  }
}

.appointment-modal {
  div.ant-modal-header {
    background-color: var(--app-quarternary-color);
    text-align: center;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.24);
    .ant-modal-title {
      color: var(--app-secondary-color);
    }
  }
}

.filter-modal {
  position: relative;
  top: 50px;
  div.ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }
  button.ant-modal-close {
    color: var(--app-secondary-color);
    .ant-modal-close-x {
      width: auto;
      height: auto;
      padding: 0 1rem;
      font-size: 1rem;
    }
    transition: color 0.3s;
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  div.ant-modal-header {
    background-color: var(--app-quarternary-color);

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: left;
    .ant-modal-title {
      color: var(--app-secondary-color);
      font-weight: 500;
    }
  }
  div.ant-modal-body {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow-y: auto;
    max-height: 28rem;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--app-quarternary-color);
  border-color: var(--app-quarternary-color);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--app-quarternary-color);

  box-shadow: 0 0 0px 3px rgba(68, 65, 183, 0.3);
}

.ant-checkbox-checked::after {
  border-color: var(--app-quarternary-color);
}

.css-2b097c-container {
  width: 60%;
  .css-yk16xz-control {
    border-radius: 20px;
    border: none;
  }
  .css-1pahdxg-control {
    border-radius: 20px;
    border: none;
  }
  .css-1pahdxg-control:hover {
    border-color: none;
  }
}

.css-1w9j89e-menu {
  color: var(--app-terciary-color);
  .css-4ljt47-MenuList {
    .css-1n7v3ny-option {
      background-color: transparent;
      transition: background-color 0.3s;
      &:hover {
        cursor: pointer;
        background-color: var(--app-quarternary-color);
        color: var(--app-secondary-color);
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  transform: translate3d(-50%, -108%, 0);
  padding: 1rem;
  button {
    top: 0px !important;
    right: 0px !important;
  }
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.15);
}

.gm-style .gm-style-iw-t::after {
  transform: translate(-50%, -285%) rotate(-45deg) !important;
}
